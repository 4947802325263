import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  AuthGet,
  AuthPost,
  Get,
  GooglePlaces,
  Post,
} from "../../common_var/httpService";
import Button from "../../Components/Button/Button";
import DateField from "../../Components/Date/DateField";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Phone from "../../Components/Phone/Phone";
import Text from "../../Components/Text/Text";
import Number from "../../Components/Number/Number";
import { useStateValue } from "../../Context/StateProvider";
import DayPicker from "../../Components/DayPicker/DayPicker";
import "react-phone-number-input/style.css";
import Argyle from "../../Components/Argyle/Argyle";
import SkipButton from "../../Components/SkipButton/SkipButton";
import AddressAutocomplete from "../../Components/Address/Address";
import { hasNullValueInObject } from "../../common_var/util";

const EmploymentInformation = ({ step }) => {
  const [
    {
      errors,
      formData,
      currentStep,
      cosignerCheckbox,
      globalStep,
      onboardConditions,
      integrationsStatus,
      isCosignorFlow,
      frames,
      isFullPreview,
      requiredFields,
    },
    dispatch,
  ] = useStateValue();
  const [value, setValue] = useState(
    formData?.employer_address !== undefined ? formData?.employer_address : null
  );
  const [months, setMonths] = useState("");
  const [form, setForm] = useState("");
  const { loanid, token } = useParams();
  const [dayPickerPopup, setDayPickerPopup] = useState(false);
  const [preventVerification, setPreventVerification] = useState(false);
  const user_id = sessionStorage.getItem("user_id");

  let payroll_date = [];
  for (let i = 1; i <= 31; i++) {
    payroll_date.push({ key: i, value: i });
  }
  const emp_status_data = [
    { key: "employed", value: "Employed" },
    { key: "self-employed", value: "Self-employed" },
    { key: "military", value: "Military" },
    // { key: "retired", value: "Retired" },
    { key: "currently unemployed", value: "Currently Unemployed" },
  ];
  const emp_type_data = [
    { key: "full time", value: "Full time" },
    { key: "part time", value: "Part time" },
  ];

  const handleChange = (e, newName) => {
    setPreventVerification(false); //TO VERIFY AGAIN IF THE FORM IS EDITED AGAIN AFTER SUBMISSION
    // let name = e.target.name;
    // let val = e.target.value;
    let name;
    let val;
    if (newName === "phone") {
      name = newName;
      val = e;
    } else {
      name = e.target.name;
      val = e.target.value;
    }
    dispatch({
      type: "SET_VALUES",
      payload: { [name]: val },
    });
  };

  const handlePhone = (e) => {
    dispatch({
      type: "SET_VALUES",
      payload: { phone: e },
    });
  };

  const today = new Date();
  let maxHireDate = new Date(
    today.getFullYear(),
    today.getMonth() - 2,
    today.getDate()
  );
  let minHireDate = new Date(
    today.getFullYear() - 50,
    today.getMonth(),
    today.getDate()
  );

  const getDetails = async () => {
    try {
      let res = await AuthGet(
        `loan/fetch-employmentinfo/${loanid}`,
        "onboarding"
      );
      if (res.data) {
        setPreventVerification(true);
      } else {
        setPreventVerification(false);
      }
      if (res.statusCode === 200) {
        dispatch({type: "CLEAR_VALUES"});
        dispatch({
          type: "SET_VALUES",
          payload: res.data[0],
        });
        setValue(res?.data[0]?.employer_address);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({type: "RESET_REQUIRED_FIELDS"});
    dispatch({
      type: "CLEAR_ERRORS",
    });
    !isFullPreview && getDetails();
  }, []);

  useEffect(() => {
    if (formData?.employer_address !== undefined) {
      setValue(formData?.employer_address);
    }
  }, [formData])

  function differenceInMonths(date2) {
    if (date2) {
      let todaydate = new Date();
      let selectedDate = new Date(date2);
      // console.warn('wewwe',todaydate)
      const monthDiff = todaydate.getMonth() - selectedDate.getMonth() + 1;
      const yearDiff = todaydate.getYear() - selectedDate.getYear();

      return monthDiff + yearDiff * 12;
    }
  }

  useEffect(() => {
    let duration = differenceInMonths(formData.date_of_hire);
    if (duration) {
      dispatch({
        type: "SET_VALUES",
        payload: { ["duration"]: duration.toString() },
      });
    }
    setMonths(
      isNaN(differenceInMonths(formData.date_of_hire)) ||
        differenceInMonths(formData.date_of_hire) < 0
        ? "Select Hire Date"
        : differenceInMonths(formData.date_of_hire)?.toString()
    );
  }, [formData.date_of_hire]);

  const mainConnection = async () => {
    const newIntegrationsStatus = { ...integrationsStatus };
    const service =
      onboardConditions.employment_info.services.length > 0
        ? onboardConditions.employment_info.services[0]
        : "";
    switch (
      service /* CURRENTLY NOT CONSIDERING MULTIPLE IDENTITY VERIFICATION SYSTEM */
    ) {
      case "Argyle":
        newIntegrationsStatus.employmentVerification.argyle.activate = true;

        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newIntegrationsStatus },
        });
        break;

      default:
        save();
        break;
    }
  };

  const save = () => {
    const bodyData = {
      loan_id: loanid,
      employment: [{ ...formData, isCosignor: cosignerCheckbox }],
      step: step,
    };

    if (!bodyData.employment[0].payroll_date) {
      return;
    }

    AuthPost("loan/update-employment-info", bodyData, "onboarding").then(
      (resp) => {
        if (resp.statusCode === 200) {
          dispatch({
            type: "CLEAR_VALUES",
          });
          dispatch({type: "RESET_REQUIRED_FIELDS"});
          dispatch({
            type: "FORM_SUBMIT",
            payload: false,
          });
          dispatch({
            type: "SET_STEP",
            payload: isCosignorFlow
              ? resp.data[0].active_cosignor_step
              : resp.data[0].step,
          });

          const nextFrame = frames.filter((el) => el.step === currentStep + 1);
          dispatch({
            type: "SET_CURRENT_FRAME",
            payload: nextFrame[0]?.name,
          });
        }
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "FORM_SUBMIT",
      payload: true,
    });

    const isFilled = hasNullValueInObject({...formData }, requiredFields);
    if(!isFilled) {
      console.log('isFilled::: ', isFilled);
      return;
    }

    const newIntegrationsStatus = { ...integrationsStatus };
    newIntegrationsStatus.employmentVerification.argyle.activate = false;
    dispatch({
      type: "CHANGE_SERVICE_STATUS",
      payload: { ...newIntegrationsStatus },
    });

    if (Object.keys(errors).length === 0) {
      try {
        // if (preventVerification) {
          save();
          return;
        // }
        // mainConnection();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAddress = async (e) => {
    setValue(e?.value?.description);
    let Address_data = await GooglePlaces(e);
    dispatch({
      type: "SET_VALUES",
      payload: {
        employer_address: Address_data.Address,
      },
    });
  };

  const handleAdd = () => {
    let prevForm = [...form];
    console.log(prevForm);
    let newForm = prevForm.push(prevForm[0]);
    console.log(newForm);
    setForm(prevForm);
  };
  return (
    <>
      <form className="row" onSubmit={handleSubmit}>
        <Dropdown
          data={emp_status_data}
          name={"employment_status"}
          labelName={"Employment Status"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        />
        <Text
          name="employer_name"
          labelName={"Employer Name"}
          placeholder="Employer Name"
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        />
        <Dropdown
          data={emp_type_data}
          name={"employment_type"}
          labelName={"Employment Type"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        />
        <Text
          name="jobtitle"
          labelName={"Job Title/Designation"}
          placeholder="Job title/Designation"
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        />
        {/* <Text
          name="employer_address"
          labelName={"Employer Address"}
          placeholder="Employer Address"
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        /> */}
        <AddressAutocomplete
          handleAddress={handleAddress}
          value={value}
          required={true}
          labelName={"Employer Address"}
          name={"employer_address"}
        />
        <Phone
          international={true}
          defaultCountry={"US"}
          name={"phone"}
          labelName="Employer contact number"
          placeholder={"Example: (000) 000-0000"}
          handleChange={handleChange}
          readOnly={isFullPreview ? true : false}
          required={true}
        />
        <DateField
          minYear={minHireDate.getFullYear()}
          minDate={minHireDate}
          maxDate={maxHireDate}
          name={"date_of_hire"}
          labelName="Hiring Date"
          placeholder={"MM/DD/YYYY"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        />
        <div className="col-md-6 mb_24">
          <label htmlFor={"duration"} className="labelText">
            {"duration"} <span className="requiredText">*</span>
          </label>
          <input
            maxLength={3}
            type="text"
            id={"duration"}
            placeholder={"Duration with Employer (in months)"}
            name={"duration"}
            className="MainInput"
            readOnly
            handleChange={handleChange}
            value={months}
          ></input>
        </div>
        {/* <Text
          maxLen={3}
          name={"duration"}
          labelName="Duration with Employer (in months)"
          placeholder={"Duration with Employer (in months)"}
          handleChange={handleChange}
          readonly={isFullPreview ? true : false}
          required={true}
        /> */}
        {/* <Dropdown
            data={payroll_freq}
            name="payroll_frequency"
            labelName={"Payroll Frequency"}
            handleChange={handleChange}
          /> */}
        {/* <Dropdown
            data={payroll_date}
            name="payroll_date"
            labelName={"Payroll Date"}
            handleChange={handleChange}
            required={true}
          />  */}
        <Number
          handleClick={() => setDayPickerPopup(!dayPickerPopup)}
          name={"payroll_date"}
          labelName={"Payroll Date"}
          placeholder={"Payroll Date"}
          handleChange={handleChange}
          required={true}
          readOnly={true}
        />
        {dayPickerPopup && !isFullPreview && (
          <DayPicker
            setDayPickerPopup={setDayPickerPopup}
            name="payroll_date"
          />
        )}
        {!isFullPreview && (
          <div className="displayFlex">
            <SkipButton />
            <Button
              type={"submit"}
              value={"Continue"}
              handleClick={handleSubmit}
            />
          </div>
        )}
        {integrationsStatus.employmentVerification["argyle"].activate && (
          <Argyle loanid={loanid} save={save} />
        )}
      </form>
    </>
  );
};

export default EmploymentInformation;
