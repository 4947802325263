import React, { useEffect } from "react";
import Plaid from "../../Components/Onboarding-Services/Plaid";
import { useStateValue } from "../../Context/StateProvider";
import { DapiIntegration } from "../../Components/Onboarding-Services/Dapi";
import { Flink } from "../../Components/Onboarding-Services/Flink";
import Saltedge from "../../Components/Onboarding-Services/Saltedge";

function Banklookup({ step, skip, goNextStep }) {
  const [{ onboardConditions, integrationsStatus }, dispatch] = useStateValue();

  useEffect(() => {
    dispatch({type: "RESET_REQUIRED_FIELDS"});
    dispatch({
      type: "CLEAR_VALUES",
    });
  }, []);

  const mainConnection = async () => {
    const newServiceResponse = { ...integrationsStatus };
    const service =
      onboardConditions?.bank_info?.services?.length > 0
        ? onboardConditions?.bank_info?.services[0]
        : "";
    switch (
      service /* CURRENTLY NOT CONSIDERING MULTIPLE IDENTITY VERIFICATION SYSTEM */
    ) {
      case "Plaid":
        newServiceResponse.bankVerification["plaid"].activate = true;
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newServiceResponse },
        });
        break;
      case "Flinks":
        newServiceResponse.bankVerification["flinks"].activate = true;
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newServiceResponse },
        });
        break;
      case "Dapi":
        newServiceResponse.bankVerification["dapi"].activate = true;
        dispatch({
          type: "CHANGE_SERVICE_STATUS",
          payload: { ...newServiceResponse },
        });
        break;
      case "Salt Edge":
          newServiceResponse.bankVerification["saltedge"].activate = true;
          dispatch({
            type: "CHANGE_SERVICE_STATUS",
            payload: { ...newServiceResponse },
          });
          break;
      default:
        break;
    }
  };

  useEffect(() => {
    mainConnection();
  }, [onboardConditions]);

  return (
    <div>
    {integrationsStatus.bankVerification['plaid'].activate ? (
      <Plaid step={step} skip={skip} goNextStep={goNextStep} />
    ) : null}

    {integrationsStatus.bankVerification['flinks'].activate ? (
      <Flink step={step} skip={skip} goNextStep={goNextStep} />
    ) : null}

    {integrationsStatus.bankVerification['dapi'].activate ? (
      <DapiIntegration step={step} skip={skip} goNextStep={goNextStep} />
    ) : null}

    {integrationsStatus.bankVerification['saltedge'].activate ? (
     <Saltedge   step={step} skip={skip} goNextStep={goNextStep}  />
    ) : null} 

  


  </div>
  );
}

export default Banklookup;
